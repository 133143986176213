import React from 'react'
import { graphql, Link } from 'gatsby'
import SEO from '../components/seo'
import Layout from '../components/layout'
import Img from 'gatsby-image'

export default function Template({ data }) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  console.log(frontmatter)
  const heroImage =
    frontmatter &&
    frontmatter.featuredImage &&
    frontmatter.featuredImage.childImageSharp &&
    frontmatter.featuredImage.childImageSharp.fluid ? (
      <Img fluid={frontmatter.featuredImage.childImageSharp.fluid} />
    ) : (
      ''
    )

  return (
    <Layout>
      <SEO title={frontmatter.title} />
      <div>
        <div>
          {heroImage}
          <h1 className="text-6xl">{frontmatter.title}</h1>
          <h2 className="text-2xl">{frontmatter.date}</h2>
          <div dangerouslySetInnerHTML={{ __html: html }} />
        </div>
      </div>
      <Link to="/">Return homepage...</Link>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
